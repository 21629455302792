export type Maybe<T> = T | undefined;
export type Nullable<T> = T | null;
export type Optional<T> = T | null | undefined;

export type BaseStoreState<T> = {
  loading: boolean;
  error: any;
  data: T;
};

export enum FORM_TYPES {
  CIOMS = 'CIOMS',
  PREGNANCY = 'PREGNANCY',
  SAE_AESI = 'SAE_AESI',
  ADVERSE_EVENTS = 'ADVERSE_EVENTS',
}

export enum USER_ROLES {
  USER = 'USER',
  MONITOR = 'MONITOR',
  ADMIN = 'ADMIN',
}

export interface FormDTO {
  _id: string;
  creator: string;
  type: FORM_TYPES;
  study: string;
  locked: boolean;
  data: Record<string, any>;
  subject: string;
  center: string;
}

export interface GetFormQueryDto {
  user?: string;
  type?: string;
  study?: string;
}

export type UpdateFormBodyDto = Partial<FormDTO> & Pick<FormDTO, '_id'>;

export interface LoginBody extends JSON {
  email: string;
  password: string;
}

export interface StudyDTO extends JSON {
  _id: string;
  name: string;
  protocolNumber: string;
  ip: string;
}

export interface UserDTO {
  _id: string;
  email: string;
  password?: string;
  role: USER_ROLES;
  firstName: string;
  lastName: string;
}

export interface DownloadFormQuery {
  study?: string[];
  center?: string[];
  type?: string[];
  subject?: string[];
  allFields?: boolean;
}

export interface SendResetEmailDto {
  email: string;
}

export interface ValidateResetTokenDto {
  token: string;
}

export interface ResetPasswordDto {
  token: string;
  password: string;
}
